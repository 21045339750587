.form-container {
    background: rgba(83, 104, 199, 0.6);
    /* max-width: 1000px !important; */
    width: 80%;
}

@media (min-width: 992px) {
    .form-container{
        max-width: 60% !important;
    }
}

.apply-title {
    font-size: 2rem;
}

.heading-container {
    /* background: darkblue; */
    opacity: 1;
    color: white;
    min-height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.question-container {
    background: rgb(14, 27, 85);
}

.question {
    font-size: 1.3rem;
    font-weight: 400;
    color: pink;
    text-align: left;
    padding-bottom: 1.5rem;
}

.response {
    color: white !important;
    background: transparent !important;
    border: none !important;
    border-bottom: 1px solid white !important;
    border-radius: 0 !important;
    padding-left: 0 !important;
}

.response:focus {
    outline:none !important;
    box-shadow: none;
}

.response-textarea {
    background: rgb(7, 17, 61) !important;
    color:white !important;
}

.response-textarea:focus {
    outline: none !important;
    box-shadow: none;
}

#input-field {
    outline:none;
    /* border-color: #6b6e73 !important; */
    border-width: 7px;
    box-shadow: none;
    font-family: MyFont;
}

#input-field-textarea {
    outline: none;
    box-shadow: none;
    font-family: MyFont;
}

.check-field {
    color:white;
    background: transparent;
    font-family: MyFont;
}

.submitButton {
    color: white;
    font-size: 1.3rem;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem !important;
    border: none;
    transition: 1s;
    font-family: MyFont;
}

.submitButton:focus {
    outline: none;
}

.response-modal-container {
    background: white;
}

.response-modal-title {
    font-size: 1.3rem;
    font-family: MyFont;
    color: black;
}

.response-container {
    display: flex;
    justify-content: space-between;
}

.confirm-text {
    color: black;
    text-align: start;
    font-weight: 600;
    line-height: 2rem;
    width: 40%;
    display: inline;
}

.confirm-value {
    color: rgb(16, 109, 146);
    text-align: right;
    /* float: right; */
    font-weight: 600;
    line-height: 2rem;
    max-width: 60%;
    /* overflow-wrap: break-word !important; */
}

.footer-btn {
    font-family: MyFont;
}