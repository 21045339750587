@font-face {
    font-family: 'MyFont';
    src: local('MyFont'), url(../../assets/fonts/MusticaPro-SemiBold.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  

.service-container {
    min-height: 90vh;
    background: #280954;
    position: relative;
    overflow: hidden;
    color: white;
    font-family: MyFont;
    padding-top: 10vh;
    /* padding: 200px; */
    z-index: 1;
}

.service-container2 {
    /* min-height: 90vh; */
    padding: 0px 3vw !important;
}

.service-intro {
    z-index: 99;
    margin-bottom: 5vh;
    
}

.service-banner-container {
    padding: 20px !important;
    background: linear-gradient(-45deg, rgb(206, 206, 56), rgb(238, 128, 147), rgb(94, 1, 94));
    background-size: 400% 400%;
    border-radius: 14px;
    font-size: 2.5rem;
    padding: 0.25rem;
    margin-bottom: 5vh;
    width: 100%;
    box-shadow: 0px 0px 10px rgb(64, 49, 49) inset,
    0px 0px 5px rgb(88, 69, 69),
    0px 0px 10px rgb(139, 124, 124),
    0px 0px 30px rgb(173, 173, 173);
    animation: banner-background-spin 5s ease infinite;
}

@keyframes banner-background-spin {
    0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.gif {
    z-index: -99;
    width: 75%;
    border-radius: 20px;
}

.service-banner {
    background: #180434;
    border-radius: 14px;
    box-shadow: 0px 0px 20px rgb(139, 124, 124) inset;
}

.service-content-container {
    margin-bottom: 1rem;
}

.service-content {
    z-index: 99;
    font-size: 1.75rem;
    line-height: 1;
}

.service-content-icon {
    margin-right: 8px;
    /* font-size: 1.5rem; */
    max-width: 35px;
    max-height: 35px;
    z-index: 1;
}

.gas-blue {
    position: absolute;
    left: -225px;
    bottom: -10vh;
    height: 50%;
}

.gas-red {
    position: absolute;
    right: -225px;
    top: 5vh;
    height: 50%;
    transform: rotateX(-180deg);
}

@media (max-width: 992px) {
    .service-banner-container{
        width: 80%;
    }

    .gas-blue {
        left: -325px;
        height: 40%;
    }

    .gas-red {
        right: -325px;
        height: 40%;
    }
}

@media (max-width: 768px) {
    .service-banner-container {
        font-size: 2rem;
    }
    .gas-blue {
        left: -200px;
        height: 30%;
    }

    .gas-red {
        top: 0vh;
        right: -200px;
        height: 30%;
    }
}

@media (max-width: 568px) {
    .service-banner-container {
        font-size: 2rem;
    }
    .gas-blue {
        left: -200px;
        height: 25%;
    }

    .gas-red {
        top: 0vh;
        right: -250px;
        height: 25%;
    }
}