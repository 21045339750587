@font-face {
  font-family: 'MyFont', 'MyFont2';
  src: local('MyFont'), url(./assets/fonts/BebasNeue-Regular.ttf) format('truetype');
  /* src: local('MyFont3'), url(./assets/fonts/SF-Pro.ttf) format('truetype'); */

  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

body {
  background: #180434 !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html {
  scroll-padding-top: 10vh;
}

p {
  font-family: MyFont;
  color: white;
}