.detail {
    min-height: 90vh;
    padding-top: 5vh;
}

.detail-heading {
    font-size: 3rem;
    font-weight: 600;
}

.detail-description {
    font-size: 1.7rem;
    font-weight: 300;
}

.detail-container {
    margin-top: 1rem !important;
    margin-bottom: 1rem;
}

.detail-image {
    width: 15% ;
    margin-top: 1rem;
    margin-bottom: 0.75rem;
    min-width: 80px !important;
}

.detail-header {
    font-size: 2rem;
    font-weight: 500;
}
.detail-content {
    font-size: 1.3rem;
    font-weight: 200;
}