.modal-container {
    background: #2b0c57 !important;
}

.modal-container2 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.project-title {
    text-align: center;
    color: white;
    font-size: 2rem;
    margin-top: 2vh;
    padding-bottom: .5rem;
    border-bottom: 2px solid pink;
}

.projects-container {
    color: white;
}

.chain {
    font-size: 1.5rem;
    color: white;
    padding-bottom: 3vh;
    margin-top: 3vh;
}

.project-container {
    padding-bottom: 5vh;
}

#logo-container {
    width: 50%;
    text-decoration: none;
    transition: 0.5s;
    color: white;
}

#logo-container:hover {
    text-decoration: underline pink;
    color: pink;
    opacity: 0.8;
}

.project-logo {
    border-radius: 50%;
    z-index: 1;
    margin-bottom: 2vh;
}

.project-name {
    display: block;
    font-size: 1rem;
    /* color: white; */
    text-align: center;
    font-family: MyFont;
}



.ethereum {
    border-bottom: 1px solid white;
}

.footer-text {
    text-align: start;
}

.footer-btn {
    font-family: MyFont;
}