p {
    color: white;
    text-decoration: none;
    margin-bottom: 0 !important;
    text-align: center;
}

.nav-logo {
    height: 8vh;
    width: auto;
    padding-left: 2rem;
}

@media (max-width: 576px) {
    .nav-logo {
        padding-left: 0;
    }
}

.navbar-custom {
    height: 11vh;
    background: #0e021f;
}


.navbar-right {
    background: #59368e ;
    /* margin-right: 2vw; */
}

.navbar-icon {
    margin-left: 2vw;
}

@media (min-width: 992px) {
    .navbar-right {
        background: transparent !important;
        margin-right: 5vw;
    }

    .navbar-icon {
        margin-left: 5vw;
    }
    
    .item {
        padding-left: 0 !important;
        border-bottom: none !important;
    }
}

.item {
    /* color: white !important; */
    padding-left: 1rem;
    border-bottom: 1px solid white ;
    font-size: 1.15rem;
    width: 80%;
    align-self: center;
    text-decoration: none !important;
}

.item:hover {
    cursor: pointer;
}

.item-text {
    color: white !important;
    transition: 0.5s;
    font-family: MyFont;
}

.item-text:hover {
    color: white !important;
    opacity: 0.7;
}

#menu-button {
    border: 0;
    outline: none;
    margin-right: 2vw;
    box-shadow: none !important;

}

#menu-button-focus {
    border:none;
    outline: none !important;
    /* border-color: #6b6e73 !important; */
    /* border-width: 7px; */
    box-shadow: none !important;
}