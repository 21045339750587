@font-face {
    font-family: 'MyFont3';
    src: local('MyFont3'), url(../../assets/fonts/SF-Pro.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }


.title-container {
    margin-bottom: auto !important;
    background: #180434;
    z-index: 2;
}

.title {
    font-family: MyFont3;
    font-size: 12rem;
    font-weight: 600;
    line-height: 1.1;
    transition: 4s;
    opacity: 0;
    animation-name: title-pop;
    animation-duration: 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    animation-delay: 0.5s;
}

@keyframes title-pop {
    100% {opacity: 1}
}

.description {
    font-family: MyFont3;
    font-size: 1.2rem;
    font-weight: 300;
    opacity: 0;
    animation-name: des-pop;
    animation-duration: 2s;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
}

@keyframes des-pop {
    100% {opacity: 0.8; font-sie:0.8rem;}
}

.ES-logo {
    width: 100%;
    height: auto;
}

.gif {
    opacity: 1;
    animation-name: gif-pop;
    animation-duration: 1s;
}

@keyframes gif-pop {
    0% {opacity: 0}
}

@media (max-width: 768px) {
    .title {
        font-size: 10rem;
    }
}

@media (max-width: 600px) {
    .title{
        font-size: 7rem;
    }
    .description{
        font-size: 1.1rem;
    }
}

@media (max-width: 460px) {
    .title{
        font-size: 6rem;
    }
    .description{
        font-size: 1rem;
    }
}

@media (max-width: 400px) {
    .title {
        font-size: 5rem;
    }
}

@media (max-width: 350px) {
    .title {
        font-size: 4rem;
    }
}

.img-container {
    background: #180434;
    height: 13vw;
    width: 13vw;
}

.img-container:hover {
    cursor: pointer;
}

@media (max-width: 991px) {
    .img-container {
        height: 50vw;
        width: 50vw;
    }
}

.img-container-left {
    position:relative;
    background: linear-gradient(-45deg, rgb(206, 206, 56), rgb(238, 128, 147), rgb(94, 1, 94));
    background-size: 400% 400%;
    padding: 3px;
    margin-left: 5vw;
    margin-bottom: 18vh;
    animation: left-spin 5s ease infinite;
    z-index: 3;

    /* animation-iteration-count: 1; */
    /* animation-fill-mode: forwards; */
}

@keyframes left-spin {
    0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.img-container-right {
    position: relative;
    background: linear-gradient(-45deg, rgb(206, 206, 56), rgb(238, 128, 147), rgb(94, 1, 94));
    background-size: 400% 400%;
    padding: 3px;
    /* margin-right: 5vw; */
    /* margin-bottom: 18vh; */
    animation: right-spin 5s ease infinite;
    z-index: 3;

}

@keyframes right-spin {
    0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.wave {
    position: absolute;
    top: 25vh;
    margin-bottom: 18vh;
    margin-left: 1vh;
    z-index: 0;
    overflow-x: hidden;
    max-width: 20vw !important;
}

.wave-left {
    left: 16vw;
}


.wave-right {
    transform: scaleX(-1);
    right: 15vw;
}

@media (max-width: 992px) {
    .wave {
        display: none;
    }
}

@media (max-width: 1500px) {
    .wave {
        top: 27vh;
    }
}

.custom-shape-divider-bottom-1644166437 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    opacity: 0.4;
}

.custom-shape-divider-bottom-1644166437 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 171px;
}

.custom-shape-divider-bottom-1644166437 .shape-fill {
    fill: rgb(182, 50, 223);
}