.button-transparent {
    /* background: radial-gradient(circle, rgba(8,0,97,1) 0%, rgba(24,4,52,1) 100%) !important; */
    border-radius: 14px;
    border-color: white !important;
    border-width: 1px;
    height: fit-content;
    transition: 1s !important;
    opacity: 0.6;
    z-index: 99;
    background: radial-gradient(circle, rgba(19,8,144,1) 0%, rgba(51,7,113,1) 100%) !important;

}

.button-transparent:hover {
    background: radial-gradient(circle, rgba(19,8,144,1) 0%, rgba(51,7,113,1) 100%) !important;
    transition: 1s !important;
    opacity: 1;
}

.button-text {
    color: white;
    font-size: 2rem;
}

.point-left {
    max-width: 50px;
    margin-right: 0.5rem;
}

.point-right {
    max-width: 50px;
    margin-left: 0.5rem;
    transform: scaleX(-1);
}