.timeline-container {
    min-height: 90vh;
    background: #180434;
    position: relative;
    overflow: hidden;
    color: white;
    font-family: MyFont;
}

.timeline-container2 {
    margin-top: 5vh;
    margin-bottom: 10vh;
    padding: 0 7vw !important;
}

.title-container {
    /* margin-top: 10vh; */
    /* margin-bottom: 5vh; */
    padding-bottom: 5vh;
}

.timeline-title {
    font-size: 3rem;
    text-align: start;
    border: 2px solid purple;
    width: fit-content;
    /* -bottom: 10vh; */
}

.timeline-description {
    font-size: 1rem;
    text-align: start;
}

@media (max-width: 568px) {
    .timeline-title {
        font-size: 2rem;
    }
}

.timeline {
    position: relative;
    min-height: 85vh;
    margin-top: 5vh;
    margin-bottom: 5vh;
}

.timeline::after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    border: 8px solid #3589a1;
    right: 0;
    z-index: 1;
    top: 8px;
    left: 50%;
    margin-left: -10px;
}

.timeline::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    border: 8px solid #d4b298;
    right: 0;
    z-index: 1;
    bottom: 8px;
    left: 50%;
    margin-left: -10px;
}

.roadmap-container {
    /* padding: 10px 40px; */
    position: relative;
    width: 50%;
    min-height: 20vh;
}


.container1.roadmap-container::before {
    content: '';
    position: absolute;
    width: 20%;
    height: 2px;
    top: calc(50%);
    right: 0;
    background: #3589a1;
    z-index: 1;
}

.container2.roadmap-container::before {
    content: '';
    position: absolute;
    width: 20%;
    height: 2px;
    top: calc(50%);
    right: 0;
    background: #dd90a7;
    z-index: 1;
}

.container3.roadmap-container::before {
    content: '';
    position: absolute;
    width: 20%;
    height: 2px;
    top: calc(50%);
    right: 0;
    background: #a6a4b4;
    z-index: 1;
}

.container4.roadmap-container::before {
    content: '';
    position: absolute;
    width: 20%;
    height: 2px;
    top: calc(50%);
    right: 0;
    background: #d4b298;
    z-index: 1;
}

.left {
    left: 0;
    padding-right: 10%;
}

.right {
    left: 50%;
    padding-left: 10%;
}

.right::after {
    left: -20px;

}

.right::before {
    left: 0px;
}



.container1::after {
    content: '';
    display: inline-block;
    background: url('../../assets/pic/roadmap1.svg');
    background-size: cover;
    position: absolute;
    width: 40px;
    height: 40px;
    left: -21px;
    background-color: white;
    /* border: 4px solid #FF9F55; */
    top: calc(50% - 20px);
    /* overflow: hidden; */
    z-index: 2;
}

.container2::after {
    content: '';
    display: inline-block;
    background: url('../../assets/pic/roadmap2.svg');
    background-size: cover;
    position: absolute;
    width: 40px;
    height: 40px;
    right: -19px;
    background-color: white;
    /* border: 4px solid #FF9F55; */
    top: calc(50% - 20px);
    /* overflow: hidden; */
    z-index: 2;
}

.container3::after {
    content: '';
    display: inline-block;
    background: url('../../assets/pic/roadmap3.svg');
    background-size: cover;
    position: absolute;
    width: 40px;
    height: 40px;
    left: -21px;
    background-color: white;
    /* border: 4px solid #FF9F55; */
    top: calc(50% - 20px);
    /* overflow: hidden; */
    z-index: 2;
}

.container4::after {
    content: '';
    display: inline-block;
    background: url('../../assets/pic/roadmap4.svg');
    background-size: cover;
    position: absolute;
    width: 40px;
    height: 40px;
    right: -19px;
    background-color: white;
    /* border: 4px solid #FF9F55; */
    top: calc(50% - 20px);
    /* overflow: hidden; */
    z-index: 2;
}

.roadmap-content {
    padding: 0px 12%;
    /* background-color: white; */
    position: relative;
    /* border-radius: 6px; */
    text-align: center;
    width: 100%;
}

.container1 .roadmap-content::after {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    top: calc(50% - 5px);
    border-radius: 6px;
    border: 6px solid #3589a1;
    right: 0;
    background: white;
    z-index: 1;
}

.container2 .roadmap-content::after {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    top: calc(50% - 5px);
    border-radius: 6px;
    border: 6px solid #dd90a7;
    right: 0;
    background: white;
    z-index: 1;
}

.container3 .roadmap-content::after {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    top: calc(50% - 5px);
    border-radius: 6px;
    border: 6px solid #a6a4b4;
    right: 0;
    background: white;
    z-index: 1;
}

.container4 .roadmap-content::after {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    top: calc(50% - 5px);
    border-radius: 6px;
    border: 6px solid #d4b298;
    right: 0;
    background: white;
    z-index: 1;
}

.content-right::after {
    left: 0px;
}

@media (max-width: 768px) {

    .timeline::after,
    .timeline::before {
        left: 38px;
    }

    .roadmap-container {
        width: 100%;
    }

    .roadmap-container::before {
        left: 55px;
        width: 50px !important;
    }

    .left::after,
    .right::after {
        left: 17px;
    }

    .right {
        left: 0%;
        padding-left: 80px;
    }

    .left {
        padding-left: 80px;
        padding-right: 0px;
    }

    .content-left::after {
        left: 2px;
    }

    .roadmap-content::after {
        left: 16px;
    }

    .container11::before,
    .container11::after,
    .container12::before,
    .container12::after,
    .container13::before,
    .container13::after,
    .container14::before,
    .container14::after {
        left: 38px !important;
    }

    .container11,
    .container12,
    .container13,
    .container14 {
        width: 100%;
    }

    .original {
        display: none !important;
    }

    .original-reverse {
        display: block !important;
    }
}

.roadmap-title {
    font-size: 1.5rem;
}

.roadmap-description {
    font-size: 1rem;
    /* color: rgb(243, 210, 216); */
}

.container1 .roadmap-title,
.container1 .roadmap-description {
    color: #3589a1;
}

.container2 .roadmap-title,
.container2 .roadmap-description {
    color: #dd90a7;
}

.container3 .roadmap-title,
.container3 .roadmap-description {
    color: #a6a4b4;
}

.container4 .roadmap-title,
.container4 .roadmap-description {
    color: #d4b298;
}

.container11::before {
    content: '';
    position: absolute;
    width: 3px;
    background-color: #3589a1;
    top: 0;
    bottom: 50%;
    left: 0;
    z-index: 2;
    margin-left: -3px;
}

.container11::after {
    content: '';
    position: absolute;
    width: 3px;
    background-color: #dd90a7;
    top: 50%;
    bottom: 0;
    left: 0;
    z-index: 2;
    margin-left: -3px;
}

.container12::before {
    content: '';
    position: absolute;
    width: 3px;
    background-color: #dd90a7;
    top: 0;
    bottom: 50%;
    right: 0;
    z-index: 2;
    margin-left: -3px;
}

.container12::after {
    content: '';
    position: absolute;
    width: 3px;
    background-color: #a6a4b4;
    top: 50%;
    bottom: 0;
    right: 0;
    z-index: 2;
    margin-left: -3px;
}

.container13::before {
    content: '';
    position: absolute;
    width: 3px;
    background-color: #a6a4b4;
    top: 0;
    bottom: 50%;
    left: 0;
    z-index: 2;
    margin-left: -3px;
}

.container13::after {
    content: '';
    position: absolute;
    width: 3px;
    background-color: #d4b298;
    top: 50%;
    bottom: 0;
    left: 0;
    z-index: 2;
    margin-left: -3px;
}

.container14::before {
    content: '';
    position: absolute;
    width: 3px;
    background-color: #d4b298;
    top: 0;
    bottom: 50%;
    right: 0;
    z-index: 2;
    margin-left: -3px;
}

.container14::after {
    content: '';
    position: absolute;
    width: 3px;
    background-color: #d4b298;
    top: 50%;
    bottom: 0;
    right: 0;
    z-index: 2;
    margin-left: -3px;
}

.original {
    display: block
}

.original-reverse {
    display: none;
}